<template>
  <div>
    
  </div>
</template>

<script>
export default {
  name: "FranchiseDirectorDetails",
};
</script>